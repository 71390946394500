<template>
  <div class="st-bo-search">
    <st-select
      :items="options"
      @change="(value) => handleChange('selectedCategory', value)"
      class="st-bo-search__select"
      :value="selectedCategory"
      :hasEmpty="false"
    />

    <div class="st-bo-search__search">
      <st-input
        id="search-input"
        :required="false"
        :invalid="false"
        tabindex="0"
        type="text"
        @input="(value) => handleChange('searchQuery', value)"
      />
      <span class="st-bo-search__search__error">
        {{ error }}
      </span>
    </div>
    <st-button
      class="st-bo-search__btn"
      @click="submit()"
      @keyup.enter="submit()"
      primary
      :disabled="!validSearch"
      tabindex="0"
    >
      Sök
    </st-button>
  </div>
</template>
<script>
import { StInput, StButton } from "skanetrafiken/dist/legacy";
import { StSelect } from "skanetrafiken";
import { useSearch } from "@/composables/useSearch";
import { computed } from "vue";

export default {
  name: "st-bo-search",
  components: {
    StSelect,
    StInput,
    StButton,
  },
  props: {
    searchType: String,
  },
  setup(props) {
    const search = useSearch();
    const options = search.getSearchOptions(props.searchType);
    search.setDefaultSearchOption(options[0].id);

    const submit = async () => {
      await search.searchData(props.searchType);
    };

    const handleChange = (key, e) => {
      search.state[key] = e.target.value;
    };

    return {
      options,
      selectedCategory: computed(() => search.state.selectedCategory),
      submit,
      handleChange,
      validSearch: computed(() => search.isValidSearch()),
      error: computed(() => search.state.inputError),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-search {
  display: flex;
  flex-direction: column;
  padding: rem-calc(25) 0;
  text-align: flex-start;

  @include large {
    flex-direction: row;

    &__select {
      width: 20%;
    }

    &__search {
      width: 20%;
    }

    & > :not(:last-child) {
      margin-right: rem-calc(15);
    }
  }

  &__btn {
    max-height: rem-calc(48);
    margin-bottom: rem-calc(16);
  }

  & > :not(:last-child) {
    margin-bottom: rem-calc(15);
  }
}
</style>
