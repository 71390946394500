<template>
  <div>
    <div class="st-bo-navigation">
      <div class="st-bo-navigation__item__logo">
        <router-link to="/" class="st-bo-navigation__item__logo__link">
          <img :class="logoBySystem" :src="imgSrc" />
          <span class="st-bo-navigation__item__logo__link__text">
            Företag BackOffice
          </span>
        </router-link>
      </div>
      <button
        v-if="isSmallScreen"
        class="st-bo-navigation__hamburger-menu"
        :tabindex="isSmallScreen ? '1' : '0'"
        @click="openDropdownMenu = !openDropdownMenu"
      >
        <template v-if="openDropdownMenu">
          <close />
          Stäng
        </template>
        <template v-else>
          <hamburger />
          Meny
        </template>
      </button>
      <ul class="st-bo-navigation__items" v-if="!isSmallScreen">
        <li class="st-bo-navigation__item" data-text="Konto">
          <router-link
            class="st-bo-navigation__item__link"
            to="/"
            :class="{
              'active-link': $route.matched.some(
                ({ name }) => name === 'AccountDetails'
              ),
            }"
          >
            Konto
          </router-link>
        </li>
        <li class="st-bo-navigation__item" data-text="Administratörer">
          <router-link class="st-bo-navigation__item__link" to="/admins">
            Administratörer
          </router-link>
        </li>
        <li class="st-bo-navigation__item" data-text="Resenärer">
          <router-link class="st-bo-navigation__item__link" to="/travellers">
            Resenärer
          </router-link>
        </li>
        <li class="st-bo-navigation__item" @click="logout()">
          <span>Logga ut</span>
        </li>
      </ul>
    </div>
    <div v-if="openDropdownMenu">
      <dropdown-menu
        :close="() => (openDropdownMenu = false)"
        @dropdownMenuClick="handleDropdownMenuSelection"
      />
    </div>
  </div>
</template>
<script>
import { useMsal } from "@/composables/useMsal";
import { useHandleWindowWidth } from "@/composables/useHandleWindowWidth";
import Hamburger from "@/components/common/Hamburger";
import { ref } from "vue";
import DropdownMenu from "@/components/DropdownMenu";
import Close from "@/components/common/Close";
export default {
  name: "st-bo-navigation",
  components: {
    Hamburger,
    DropdownMenu,
    Close,
  },
  setup() {
    const { instance } = useMsal();
    const systemName = process.env.VUE_APP_SYSTEM_APP;
    const { isSmallScreen } = useHandleWindowWidth();
    const openDropdownMenu = ref(false);
    const handleDropdownMenuSelection = () => {
      openDropdownMenu.value = false;
    };

    const logout = () => {
      instance.logoutRedirect();
    };

    return {
      logout,
      logoBySystem: `logo-${systemName}`,
      imgSrc: `/logo-${
        systemName === "st" ? `${systemName}.jpg` : `${systemName}.png`
      }`,
      isSmallScreen,
      openDropdownMenu,
      handleDropdownMenuSelection,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-navigation {
  display: flex;
  font-size: rem-calc(20);
  border-bottom: rem-calc(1) solid $light-grey;
  color: $dark-grey;
  justify-content: space-between;
  z-index: 0;

  &__hamburger-menu {
    display: flex;
    flex-direction: column;
    font-size: rem-calc(10);
    align-items: center;
    border: none;
    font-family: inherit;
    padding: rem-calc(19) rem-calc(20);
  }

  &__items {
    display: flex;
    flex-direction: row;
    list-style: none;

    & > :not(:last-child) {
      margin-right: rem-calc(60);
    }

    & > :last-child {
      background: $orange;
      cursor: pointer;
    }
  }

  &__item {
    display: inline-flex;
    flex-direction: column;
    padding: rem-calc(30) rem-calc(20);
    text-decoration: none;

    &::after {
      content: attr(data-text) / "";
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: bold;
    }

    &__link {
      text-decoration: none;
      color: $dark-grey;
    }

    &__logo {
      display: flex;
      align-items: center;
      margin-left: rem-calc(10);

      &__link {
        text-decoration: none;

        &__text {
          margin-top: rem-calc(15);
          margin-left: rem-calc(10);
          font-family: $bitter;
          color: $grey;
        }

        .logo-st {
          height: rem-calc(30);
        }

        .logo-bt {
          height: rem-calc(48);
        }

        .logo-ot {
          height: rem-calc(48);
        }
      }

      .active-link,
      .active-link:hover {
        font-weight: normal;
        text-decoration: none;
        text-underline-offset: 0;
      }
    }
  }
}

.active-link,
.active-link:hover {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: rem-calc(8);
  text-decoration-color: $orange;
  text-decoration-thickness: rem-calc(3);
}
</style>
