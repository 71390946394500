<template>
  <div class="st-bo-account-admins">
    <st-spinner
      v-if="loading"
      aria-label="Laddar"
      class="st-bo-account-admins__spinner"
    />
    <span v-else-if="error" class="st-bo-account-admins__error-text">
      {{ error }}
    </span>
    <div v-else class="st-bo-account-admins__content">
      <div class="st-bo-account-admins__content__handle">
        <span>Antal administratörer: {{ admins.length }}</span>
        <st-button
          primary
          @click="() => (showAddModal = true)"
          tabindex="0"
          :disabled="isNotAdmin"
        >
          Bjud in administratör
        </st-button>
      </div>
      <h4>Administratörer</h4>
      <st-table
        :list-data="admins"
        :headers="headers"
        @on-click="rowClick"
      ></st-table>
      <admin-info-modal
        v-if="showAdminModal"
        :open="showAdminModal"
        :account-admin="true"
        @handle-block="handleBlockModal"
        @handle-remove="handleRemoveModal"
        @close="() => (showAdminModal = false)"
      />
      <add-admin-modal
        v-if="showAddModal"
        :open="showAddModal"
        @close="() => (showAddModal = false)"
        @add="handleAdd"
      />
      <block-admin-modal
        :open="showBlockAdminModal.show"
        :text="showBlockAdminModal.text"
        @block="blockAdmin"
        @close="handleBlockModal"
        :isBlocked="adminBlocked"
      />
      <remove-modal
        :open="showRemoveModal.show"
        title="Ta bort administratör"
        :text="showRemoveModal.text"
        @remove="removeAdmin"
        @close="handleRemoveModal"
      />
      <confirmation-modal
        :open="showConfirmationModal.show"
        :title="showConfirmationModal.title"
        :status="showConfirmationModal.status"
        @close="handleConfirmationModal"
      />
    </div>
  </div>
</template>
<script>
import { StTable, StButton, StSpinner } from "skanetrafiken/dist/legacy";
import { useAccount } from "@/composables/useAccount";
import { useTable } from "@/composables/useTable";
import { useUser } from "@/composables/useUser";
import { useAdmin } from "@/composables/useAdmin";
import { ref, onMounted, computed } from "vue";
import AddAdminModal from "@/components/modals/AddAdminModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { ADMIN, ADMIN_ACCOUNTS } from "@/constants";
import AdminInfoModal from "@/components/modals/AdminInfoModal.vue";
import BlockAdminModal from "@/components/modals/BlockAdminModal.vue";
import RemoveModal from "@/components/modals/RemoveModal.vue";

export default {
  name: "st-bo-account-admins",
  components: {
    StButton,
    StTable,
    StSpinner,
    AddAdminModal,
    ConfirmationModal,
    AdminInfoModal,
    BlockAdminModal,
    RemoveModal,
  },
  setup() {
    const account = useAccount();
    const user = useUser();
    const table = useTable();
    const admin = useAdmin();
    const showAdminModal = ref(false);
    const showBlockAdminModal = ref({ show: false, text: "" });
    const showRemoveModal = ref({ show: false, text: "" });

    const showAddModal = ref(false);
    const showConfirmationModal = ref({
      show: false,
      title: "",
      status: false,
    });

    onMounted(async () => {
      await account.getAccountAdmins();
    });

    const handleAdd = async (email) => {
      const res = await admin.addAdmin(email, account.state.account.accountId);
      showAddModal.value = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? "Administratör inbjuden."
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const handleBlockModal = () => {
      showAdminModal.value = !showAdminModal.value;
      showBlockAdminModal.value = {
        show: !showBlockAdminModal.value.show,
        text: `Är du säker på att du vill ${
          admin.state.admin.blocked ? "aktivera" : "spärra"
        } ${admin.state.admin.givenName} ${admin.state.admin.surname}?`,
      };
    };

    const handleRemoveModal = () => {
      showAdminModal.value = !showAdminModal.value;
      showRemoveModal.value = {
        show: !showRemoveModal.value.show,
        text: `Är du säker på att du vill ta bort ${admin.state.admin.givenName} ${admin.state.admin.surname} från kontot ${admin.state.selectedAdminAccount.displayName}?`,
      };
    };

    const blockAdmin = async () => {
      const res = await admin.blockAdmin(ADMIN_ACCOUNTS);
      showBlockAdminModal.value.show = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? `${admin.state.admin.givenName} ${admin.state.admin.surname} är nu 
            ${admin.state.admin.blocked ? "spärrad" : "aktiverad"}.`
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const removeAdmin = async () => {
      const res = await admin.removeAdminFromAccount(
        admin.state.selectedAdminAccount.accountId,
        admin.state.admin.userId,
        ADMIN_ACCOUNTS
      );
      showRemoveModal.value.show = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? `${admin.state.admin.givenName} ${admin.state.admin.surname} är nu borttagen från ${admin.state.selectedAdminAccount.displayName}.`
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const handleConfirmationModal = () => {
      showConfirmationModal.value.show = false;
      showAdminModal.value = true;
    };

    const rowClick = async (row, index) => {
      admin.setSelectedAdmin(account.state.account.admins[index]);
      await admin.getAdminDetails();
      showAdminModal.value = true;
    };

    return {
      headers: table.getTableHeaders(ADMIN),
      showAddModal,
      handleAdd,
      admins: computed(() =>
        account.state.account.admins.map((admin) => admin.tableInfo)
      ),
      loading: computed(() => account.state.accountAdminsLoading),
      error: computed(() => account.state.errorAdminTab),
      showConfirmationModal,
      isNotAdmin: computed(() => !user.state.isAdmin),
      adminBlocked: computed(() => admin.state.admin.blocked),
      showAdminModal,
      rowClick,
      handleBlockModal,
      handleRemoveModal,
      blockAdmin,
      showBlockAdminModal,
      showRemoveModal,
      removeAdmin,
      handleConfirmationModal,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-account-admins {
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    text-align: start;

    &__handle {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: rem-calc(22) 0;
      font-size: rem-calc(18);

      & > :first-child {
        margin-right: rem-calc(80);
      }
    }
  }

  &__spinner {
    margin-top: rem-calc(16);
  }

  &__error-text {
    margin-top: rem-calc(16);
    text-align: start;
    font-weight: bold;
  }
}
</style>
