<template>
  <div>
    <ul class="st-bo-tabs-header">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.props.title"
        @click="selectTab(index)"
        class="st-bo-tabs-header__title"
        :class="{
          'st-bo-tabs-header__title--is-selected': index === selectedIndex,
        }"
      >
        <span>{{ tab.props.title }}</span>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
import { onMounted, reactive, provide, onBeforeMount, toRefs } from "vue";

export default {
  name: "st-bo-tabs",

  setup(_, { slots }) {
    const tabState = reactive({
      selectedIndex: 0,
      tabs: [],
      count: 0,
    });

    provide("TabsProvider", tabState);

    onBeforeMount(() => {
      if (slots.default) {
        tabState.tabs = slots.default();
      }
    });

    onMounted(() => {
      selectTab(0);
    });

    const selectTab = (i) => {
      tabState.selectedIndex = i;
    };

    return {
      ...toRefs(tabState),
      selectTab,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-tabs-header {
  display: flex;
  flex-direction: row;
  margin-bottom: rem-calc(40);
  overflow: auto;
  padding: rem-calc(20) 0 rem-calc(20) 0;

  & > :not(:last-child) {
    margin-right: rem-calc(50);
  }

  &__title {
    font-size: rem-calc(22);
    font-family: $bitter;
    color: $dark-grey;
    cursor: pointer;

    &--is-selected {
      text-decoration: underline;
      font-weight: bold;
      text-underline-offset: rem-calc(8);
      text-decoration-color: $orange;
      text-decoration-thickness: rem-calc(3);
    }

    &--is-disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
</style>
