<template>
  <div class="st-bo-account-details">
    <div class="st-bo-account-details__header">
      <h2 class="st-bo-account-details__header__title">Kontodetaljer</h2>
      <div class="st-bo-account-details__header__status">
        <status
          :status="!account.blocked"
          :title="account.blocked ? 'Spärrat' : 'Aktivt Konto'"
        />
        <status
          :status="account.isCreditWorthy"
          :title="
            account.isCreditWorthy
              ? 'Konto är aktiverat för köp'
              : 'Konto är inaktiverat för köp'
          "
        />
      </div>
    </div>
    <div class="st-bo-account-details__row">
      <st-input
        :value="account.accountName"
        :disabled="!editDetails"
        :label-name="'Kontonamn'"
        id="account-details-input-accName"
        :required="false"
        tabindex="0"
        :invalid="false"
        @input="(value) => handleInput('accountName', value)"
      />
      <st-input
        :value="account.organizationName"
        disabled
        :label-name="'Organisationsnamn'"
        id="account-details-input-orgName"
        :required="false"
        tabindex="0"
        :invalid="false"
        @input="(value) => handleInput('organizationName', value)"
      />
    </div>
    <div class="st-bo-account-details__row">
      <st-form-group
        class="st-bo-account-details__row__UG-number"
        label="UG Nummer"
        :using-composition-api="true"
        :validation="v$.ugNumber"
        :validation-errors="validationErrors.ugNumber"
      >
        <st-input
          :value="account.ugNumber"
          :disabled="!editDetails"
          id="account-details-input-ugNbr"
          :required="false"
          tabindex="0"
          @input="(value) => handleInput('ugNumber', value)"
          :blur="() => handleBlur()"
          :invalid="v$ && v$.ugNumber && v$.ugNumber.$error"
        />
      </st-form-group>
      <st-input
        :value="account.organizationNumber"
        disabled
        :label-name="'Organisationsnummer'"
        id="account-details-input-orgNbr"
        :required="false"
        tabindex="0"
        :invalid="false"
        @input="(value) => handleInput('organizationNumber', value)"
      />
    </div>
    <div class="st-bo-account-details__account-number">
      <st-input
        class="st-bo-account-details__account-number__input"
        :value="account.accountId"
        disabled
        :label-name="'Kundnummer'"
        id="account-details-input-accNbr"
        :required="false"
        tabindex="0"
        :invalid="false"
      />
    </div>
    <div class="st-bo-account-details__buttons">
      <st-button
        tabindex="0"
        @click="() => (showPurchaseModal = true)"
        :disabled="isNotAdmin"
      >
        {{
          account.isCreditWorthy ? "Inaktivera för köp " : "Aktivera för köp"
        }}
      </st-button>
      <st-button
        tabindex="0"
        @click="() => (showBlockModal = true)"
        :disabled="isNotAdmin"
      >
        {{ account.blocked ? "Aktivera konto" : "Spärra konto" }}
      </st-button>
      <st-button
        tabindex="0"
        @click="updateOrgName"
        :disabled="isNotAdmin"
        :is-loading="orgNameLoading"
      >
        Synka org. namn
      </st-button>
      <st-button
        tabindex="0"
        primary
        @click="updateDetails"
        :disabled="isNotAdmin"
        :is-loading="detailsLoading"
      >
        {{ editDetails ? "Spara uppgifter" : "Ändra uppgifter" }}
      </st-button>

      <st-information-box
        class="st-bo-account-details__buttons__feedback"
        v-if="detailsFeedback.show"
        :information-type="detailsFeedback.success ? 'success' : 'failure'"
        small
      >
        {{ detailsFeedback.text }}
      </st-information-box>
    </div>
    <block-account-modal
      :open="showBlockModal"
      @close="() => (showBlockModal = false)"
      @open-confirm-modal="handleConfirmBlockModal"
      :account-name="account.accountName"
      :organization-number="account.organizationNumber"
      :is-blocked="account.blocked"
    />
    <confirm-block-account-modal
      :open="showConfirmBlockModal.show"
      @close="handleConfirmBlockModal"
      @block="updateAccountBlock"
      :account-name="account.accountName"
      :block-on-org-number="showConfirmBlockModal.checked"
      :organization-number="account.organizationNumber"
      :is-blocked="account.blocked"
    />
    <handle-purchase-status-modal
      :open="showPurchaseModal"
      @close="() => (showPurchaseModal = false)"
      @change="updatePurchaseStatus"
      :purchase-allowed="account.isCreditWorthy"
    />
    <confirmation-modal
      :open="showConfirmationModal.show"
      :title="showConfirmationModal.title"
      :status="showConfirmationModal.status"
      @close="() => (showConfirmationModal.show = false)"
    />
  </div>
</template>
<script>
import BlockAccountModal from "@/components/modals/BlockAccountModal.vue";
import ConfirmBlockAccountModal from "@/components/modals/ConfirmBlockAccountModal.vue";
import HandlePurchaseStatusModal from "@/components/modals/HandlePurchaseStatusModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Status from "@/components/common/Status.vue";
import {
  StInput,
  StButton,
  StFormGroup,
  StInformationBox,
} from "skanetrafiken/dist/legacy";
import { useAccount } from "@/composables/useAccount";
import { useUser } from "@/composables/useUser";
import { computed, ref } from "vue";
import ChangeUgNumberValidations from "@/validators/change-ug-number-validations";
import ChangeUgNumberValidationsErrors from "@/validators/change-ug-number-validations-errors";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "st-bo-account-details",
  components: {
    StButton,
    StInput,
    BlockAccountModal,
    ConfirmBlockAccountModal,
    HandlePurchaseStatusModal,
    ConfirmationModal,
    Status,
    StInformationBox,
    StFormGroup,
  },
  setup() {
    const account = useAccount();
    const user = useUser();
    const editDetails = ref(false);
    const detailsFeedback = ref({ show: false, text: "", success: false });
    const detailsLoading = ref(false);
    const orgNameLoading = ref(false);
    const showBlockModal = ref(false);
    const showConfirmBlockModal = ref({ show: false, checked: false });
    const showPurchaseModal = ref(false);
    const showConfirmationModal = ref({
      show: false,
      title: "",
      status: false,
    });
    const v$ = useVuelidate(ChangeUgNumberValidations, account.state.account);

    const handleInput = (key, e) => {
      account.state.account[key] = e.target.value;
    };

    const handleConfirmBlockModal = (checked) => {
      showBlockModal.value = false;
      showConfirmBlockModal.value = {
        show: !showConfirmBlockModal.value.show,
        checked,
      };
    };

    const updateAccountBlock = async (blockOnOrgNumber) => {
      const res = await account.updateAccountBlock(blockOnOrgNumber);
      showConfirmBlockModal.value = false;
      const blocked = account.state.account.blocked;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? blockOnOrgNumber
            ? `Samtliga konton under ${
                account.state.account.organizationNumber
              } är nu 
            ${blocked ? "spärrade" : "aktiverade"}.`
            : `${account.state.account.accountName} är nu ${
                blocked ? "spärrat" : "aktiverat"
              }.`
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const updateDetails = async () => {
      if (editDetails.value) {
        detailsLoading.value = true;
        const res = await account.updateAccountDetails();
        detailsLoading.value = false;
        detailsFeedback.value = {
          show: true,
          text: res
            ? "Uppgifter sparades"
            : "Oops, verkar som något gick snett!",
          success: res,
        };
        setTimeout(() => {
          detailsFeedback.value.show = false;
        }, 2000);
        editDetails.value = false;
      } else {
        editDetails.value = true;
      }
    };

    const updatePurchaseStatus = async () => {
      const res = await account.updatePurchaseStatus(
        !account.state.account.isCreditWorthy
      );
      showPurchaseModal.value = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? `${account.state.account.accountName} är nu ${
              account.state.account.isCreditWorthy ? "aktiverat" : "inaktiverat"
            } för köp.`
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const updateOrgName = async () => {
      orgNameLoading.value = true;
      const res = await account.getAccountOrgName();
      orgNameLoading.value = false;
      detailsFeedback.value = {
        show: true,
        text: res
          ? "Organisationsnamn hämtat"
          : "Oops, verkar som något gick snett!",
        success: res,
      };
      setTimeout(() => {
        detailsFeedback.value.show = false;
      }, 2000);
    };

    const handleBlur = () => {
      v$.value.ugNumber.$touch();
    };

    return {
      account: computed(() => account.state.account),
      editDetails,
      detailsLoading,
      orgNameLoading,
      updateDetails,
      showBlockModal,
      showConfirmBlockModal,
      showPurchaseModal,
      showConfirmationModal,
      updatePurchaseStatus,
      handleConfirmBlockModal,
      updateAccountBlock,
      handleInput,
      isNotAdmin: computed(() => !user.state.isAdmin),
      updateOrgName,
      detailsFeedback,
      v$,
      handleBlur,
      validationErrors: ChangeUgNumberValidationsErrors,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-account-details {
  display: flex;
  flex-direction: column;
  text-align: start;
  padding-bottom: rem-calc(16);

  @include large {
    width: 70vw;
  }

  &__header {
    display: flex;
    margin-bottom: rem-calc(15);
    flex-direction: column;

    @include large {
      flex-direction: row;
      align-items: center;
    }

    &__title {
      font-size: rem-calc(25);
      margin-right: rem-calc(15);
    }

    &__status {
      display: flex;
      flex-direction: column;

      @include large {
        flex-direction: row;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    & > * {
      line-height: rem-calc(18);
    }

    & > :not(:last-child) {
      margin-bottom: rem-calc(15);
    }

    @include large {
      flex-direction: row;

      & > :not(:last-child) {
        margin-right: rem-calc(15);
        margin-bottom: 0;
      }
    }

    &__feedback {
      padding: rem-calc(8) rem-calc(12);
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: rem-calc(10);

    &__UG-number {
      flex-grow: 1;
      margin-top: 0;
    }

    @include large {
      flex-direction: row;
      align-items: center;

      & > :only-child {
        max-width: 50%;
      }

      & > :not(:last-child) {
        margin-right: rem-calc(15);
      }
    }
  }

  &__account-number {
    margin-bottom: rem-calc(15);

    @include large {
      margin-right: 0.9375rem;

      &__input {
        width: 50%;
      }
    }
  }
}
</style>
