<template>
  <st-modal
    class="st-bo-add-admin-modal"
    @esc-close="$emit('close')"
    v-if="open"
  >
    <template v-slot:header>
      <h1 class="st-bo-add-admin-modal__title">Lägg till administratör</h1>
    </template>
    <template v-slot:body>
      <div class="st-bo-add-admin-modal__input">
        <st-form-group
          label="Mejladress"
          required
          :validation="v$.email"
          :validation-errors="validationErrors.email"
          :using-composition-api="true"
        >
          <st-input
            id="add-admin-modal-input-mail"
            type="text"
            placeholder="Mejladress"
            :value="newAdmin.email"
            @input="(value) => handleInput(value)"
            tabindex="0"
            :required="true"
            :blur="() => handleBlur()"
            :invalid="v$ && v$.email && v$.email.$error"
          />
        </st-form-group>
      </div>
      <div class="st-bo-add-admin-modal__buttons">
        <st-button full-width @click="$emit('close')" tabindex="0"
          >Avbryt</st-button
        >
        <st-button primary full-width @click="addAdmin" tabindex="0">
          Lägg till
        </st-button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import {
  StModal,
  StButton,
  StInput,
  StFormGroup,
} from "skanetrafiken/dist/legacy";
import { ref } from "vue";
import AddAdminValidations from "@/validators/add-admin-validations";
import AddAdminValidationsErrors from "@/validators/add-admin-validations-errors";
import { useVuelidate } from "@vuelidate/core";
export default {
  name: "st-bo-add-admin-modal",
  components: {
    StModal,
    StButton,
    StInput,
    StFormGroup,
  },
  props: {
    open: { default: false },
  },
  setup(_, { emit }) {
    const newAdmin = ref({ email: "" });
    const v$ = useVuelidate(AddAdminValidations, newAdmin);

    const handleInput = (e) => {
      newAdmin.value.email = e.target.value;
    };

    const handleBlur = () => {
      v$.value.email.$touch();
    };

    const addAdmin = () => {
      v$.value.$touch();
      const errors = v$.value.$errors;
      if (errors.length) return;
      emit("add", newAdmin.value.email);
    };

    return {
      newAdmin,
      handleInput,
      addAdmin,
      v$,
      handleBlur,
      validationErrors: AddAdminValidationsErrors,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-add-admin-modal {
  &__title {
    text-align: center;
  }

  &__input {
    margin: rem-calc(30) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
