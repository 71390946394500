export default {
  street: [
    {
      key: "required",
      message: "Adress är obligatoriskt.",
      id: "streetRequired",
    },
  ],
  postalCode: [
    {
      key: "required",
      message: "Postnummer är obligatoriskt.",
      id: "postalCodeRequired",
    },
  ],
  city: [
    {
      key: "required",
      message: "Ort är obligatoriskt.",
      id: "cityRequired",
    },
  ],
};
