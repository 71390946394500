<template>
  <st-modal
    class="st-bo-block-admin-modal"
    @esc-close="$emit('close')"
    v-if="open"
  >
    <template v-slot:header>
      <h1>{{ isBlocked ? "Aktivera" : "Spärra" }} administratör</h1>
    </template>
    <template v-slot:body>
      <p class="st-bo-block-admin-modal__paragraph">
        {{ text }}
      </p>
      <div class="st-bo-block-admin-modal__buttons">
        <st-button full-width @click="$emit('close')" tabindex="0">
          Avbryt
        </st-button>
        <st-button primary full-width @click="$emit('block')" tabindex="0">
          {{ isBlocked ? "Aktivera" : "Spärra" }}
        </st-button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import { StModal, StButton } from "skanetrafiken/dist/legacy";
export default {
  name: "st-bo-block-admin-modal",
  components: {
    StModal,
    StButton,
  },
  props: {
    open: { default: false },
    text: String,
    isBlocked: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-block-admin-modal {
  text-align: center;
  &__paragraph {
    margin: rem-calc(30) 0 rem-calc(30) 0;
  }

  &__buttons {
    display: flex;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
