import { reactive } from "vue";
import axios from "@/axios";
import { useSearch } from "@/composables/useSearch";
import { useTable } from "@/composables/useTable";
import { useAccount } from "@/composables/useAccount";
import {
  ADMIN_ACCOUNTS,
  UPDATE_ADMIN_BLOCKED,
  UPDATE_ADMIN_TOTAL_ACCOUNTS,
  UPDATE_ADMIN_INFO,
} from "@/constants";
import config from "~/config";

const state = reactive({
  loading: false,
  selectedAdminAccount: {
    accountId: 0,
    displayName: "",
    organizationNumber: "",
  },
  admin: {
    userId: 0,
    ssn: "",
    phoneNumber: "",
    email: "",
    totalAccounts: "",
    givenName: "",
    surname: "",
    blocked: false,
    accounts: [],
    staticPhoneNumber: "", // Både tel. nr och email används som nycklar vid en post, så de gamla värdena måste sparas undan
    staticEmail: "",
  },
});

export const useAdmin = () => {
  const search = useSearch();
  const account = useAccount();
  const table = useTable();

  const getAdminDetails = async () => {
    try {
      state.loading = true;
      const { data } = await axios.get(
        `${config.BASE_URL}/GetDetailedUserInfo?ssn=${state.admin.ssn}&phoneNumber=${state.admin.phoneNumber}&emailAddress=${state.admin.email}`
      );
      updateAdmin();

      state.admin.accounts = data.accounts.map((account) => {
        return {
          tableInfo: table.getTableInfo(ADMIN_ACCOUNTS, account),
          ...account,
        };
      });
      state.loading = false;
    } catch (error) {
      console.log(error);
      state.loading = false;
    }
  };

  const setSelectedAdmin = (admin) => {
    state.admin = {
      ...state.admin,
      ...admin,
    };
  };

  const setSelectedAdminAccount = (account) => {
    state.selectedAdminAccount = account;
  };

  const updateAdminInfo = async () => {
    const payload = {
      ssn: state.admin.ssn,
      phoneNumber: state.admin.staticPhoneNumber,
      emailAddress: state.admin.staticEmail,
      newPhoneNumber: state.admin.phoneNumber,
      newEmailAddress: state.admin.email,
    };
    try {
      const { status } = await axios.put(
        `${config.BASE_URL}/UpdateUserContactInfo`,
        payload
      );

      const updateValues = ["phoneNumber", "email"];

      table.updateTableInfo(state.admin.tableInfo, state.admin, updateValues);
      search.updateAdminSearchResult(state.admin, UPDATE_ADMIN_INFO);

      updateAdmin();

      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const blockAdmin = async (adminType) => {
    const blocked = !state.admin.blocked;
    const payload = {
      ssn: state.admin.ssn,
      status: blocked,
    };
    try {
      const { status } = await axios.put(
        `${config.BASE_URL}/UpdateUserBlockedStatus`,
        payload
      );

      state.admin = { ...state.admin, blocked: blocked };

      const updateValues = ["blocked"];
      const text = state.admin.blocked ? "Spärrad" : "Ja";

      table.updateTableInfo(
        state.admin.tableInfo,
        state.admin,
        updateValues,
        text
      );

      if (adminType === ADMIN_ACCOUNTS) {
        account.updateAccountAdmin(state.admin);
      } else {
        search.updateAdminSearchResult(state.admin, UPDATE_ADMIN_BLOCKED);
      }

      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const addAdmin = async (email, accountId) => {
    const payload = { accountId, email };
    try {
      const { status } = await axios.post(
        `${config.BASE_URL}/CreateInvitation`,
        payload
      );
      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const updateAdmin = () => {
    state.admin = {
      ...state.admin,
      staticPhoneNumber: state.admin.phoneNumber,
      staticEmail: state.admin.email,
    };
  };

  const removeAdminFromAccount = async (accountId, userId, adminType) => {
    const payload = {
      accountId,
      userId,
    };
    try {
      const { status } = await axios.delete(
        `${config.BASE_URL}/DeleteAccountUser`,
        {
          data: payload,
        }
      );

      state.admin = {
        ...state.admin,
        totalAccounts: state.admin.totalAccounts - 1,
        accounts: state.admin.accounts.filter(
          (account) => account.accountId !== accountId
        ),
      };

      const updateValues = ["totalAccounts"];

      table.updateTableInfo(state.admin.tableInfo, state.admin, updateValues);

      if (adminType === ADMIN_ACCOUNTS) {
        //Har det borttagna kontot blivit borttaget via detta kontot så ska det bort från listan
        account.isSelectedAccount(accountId)
          ? account.removeAccountAdmin(userId)
          : account.updateAccountAdmin(state.admin);
      } else {
        search.updateAdminSearchResult(
          state.admin,
          UPDATE_ADMIN_TOTAL_ACCOUNTS
        );
      }

      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const updateSingleAccountUser = async (accountId) => {
    const payload = {
      email: state.admin.email,
      phoneNumber: state.admin.phoneNumber,
    };
    try {
      const { status } = await axios.put(
        `${config.BASE_URL}/UpdateAccountUser?userId=${state.admin.userId}&accountId=${accountId}`,
        payload
      );

      updateAdmin();
      const updateValues = ["phoneNumber", "email"];

      table.updateTableInfo(state.admin.tableInfo, state.admin, updateValues);
      account.updateAccountAdmin(state.admin);
      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return {
    state,
    addAdmin,
    getAdminDetails,
    setSelectedAdmin,
    updateAdminInfo,
    blockAdmin,
    removeAdminFromAccount,
    setSelectedAdminAccount,
    updateSingleAccountUser,
  };
};
