<template>
  <div class="st-bo-admin-view">
    <h1 class="st-bo-admin-view__title">Administratör</h1>
    <search :search-type="ADMIN" />
    <h2 class="st-bo-admin-view__subtitle">Administratörer</h2>
    <span v-if="result.length" class="st-bo-admin-view__count-text">
      Antal resultat: {{ result.length }}
    </span>
    <st-table :list-data="result" :headers="headers" @on-click="rowClick" />
    <st-spinner v-if="loading" aria-label="Laddar" />
    <span v-if="error" class="st-bo-admin-view__error-text">{{ error }}</span>
    <admin-info-modal
      v-if="showAdminModal"
      :open="showAdminModal"
      @handle-block="handleBlockModal"
      @handle-remove="handleRemoveModal"
      @close="() => (showAdminModal = false)"
    />
    <block-admin-modal
      :open="showBlockAdminModal.show"
      :text="showBlockAdminModal.text"
      @block="blockAdmin"
      @close="handleBlockModal"
      :isBlocked="adminBlocked"
    />
    <remove-modal
      :open="showRemoveModal.show"
      title="Ta bort administratör"
      :text="showRemoveModal.text"
      @remove="removeAdmin"
      @close="handleRemoveModal"
    />
    <confirmation-modal
      :open="showConfirmationModal.show"
      :title="showConfirmationModal.title"
      :status="showConfirmationModal.status"
      @close="handleConfirmationModal"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "vue";
import Search from "@/components/Search.vue";
import { useSearch } from "@/composables/useSearch";
import { StTable, StSpinner } from "skanetrafiken/dist/legacy";
import { ADMIN } from "@/constants";
import { useAdmin } from "@/composables/useAdmin";
import { useTable } from "@/composables/useTable";
import AdminInfoModal from "@/components/modals/AdminInfoModal.vue";
import BlockAdminModal from "@/components/modals/BlockAdminModal.vue";
import RemoveModal from "@/components/modals/RemoveModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default {
  name: "st-bo-admin-view",
  components: {
    Search,
    StTable,
    StSpinner,
    AdminInfoModal,
    BlockAdminModal,
    ConfirmationModal,
    RemoveModal,
  },
  setup() {
    const search = useSearch();
    const admin = useAdmin();
    const table = useTable();

    const showAdminModal = ref(false);
    const showBlockAdminModal = ref({ show: false, text: "" });
    const showRemoveModal = ref({ show: false, text: "" });
    const showConfirmationModal = ref({
      show: false,
      title: "",
      status: false,
    });

    onBeforeMount(() => {
      search.clearSearch();
    });

    const handleBlockModal = () => {
      showAdminModal.value = !showAdminModal.value;
      showBlockAdminModal.value = {
        show: !showBlockAdminModal.value.show,
        text: `Är du säker på att du vill ${
          admin.state.admin.blocked ? "aktivera" : "spärra"
        } ${admin.state.admin.givenName} ${admin.state.admin.surname}?`,
      };
    };

    const handleRemoveModal = () => {
      showAdminModal.value = !showAdminModal.value;
      showRemoveModal.value = {
        show: !showRemoveModal.value.show,
        text: `Är du säker på att du vill ta bort ${admin.state.admin.givenName} ${admin.state.admin.surname} från kontot ${admin.state.selectedAdminAccount.displayName}?`,
      };
    };

    const handleConfirmationModal = () => {
      showConfirmationModal.value.show = false;
      showAdminModal.value = true;
    };

    const blockAdmin = async () => {
      const res = await admin.blockAdmin(ADMIN);
      showBlockAdminModal.value.show = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? `${admin.state.admin.givenName} ${admin.state.admin.surname} är nu 
            ${admin.state.admin.blocked ? "spärrad" : "aktiverad"}.`
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const rowClick = async (row, index) => {
      admin.setSelectedAdmin(search.state.result[index]);
      await admin.getAdminDetails();
      showAdminModal.value = true;
    };

    const removeAdmin = async () => {
      const res = await admin.removeAdminFromAccount(
        admin.state.selectedAdminAccount.accountId,
        admin.state.admin.userId,
        ADMIN
      );
      showRemoveModal.value.show = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? `${admin.state.admin.givenName} ${admin.state.admin.surname} är nu borttagen från ${admin.state.selectedAdminAccount.displayName}.`
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    return {
      headers: table.getTableHeaders(ADMIN),
      result: computed(() =>
        search.state.result.map((admin) => admin.tableInfo)
      ),
      error: computed(() => search.state.resultError),
      loading: computed(() => search.state.loading),
      rowClick,
      showAdminModal,
      showBlockAdminModal,
      showRemoveModal,
      ADMIN,
      handleBlockModal,
      blockAdmin,
      showConfirmationModal,
      handleConfirmationModal,
      handleRemoveModal,
      removeAdmin,
      adminBlocked: computed(() => admin.state.admin.blocked),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-admin-view {
  padding: rem-calc(20);
  display: flex;
  flex-direction: column;
  text-align: start;

  &__title {
    text-align: start;
  }

  &__subtitle {
    text-align: start;
    font-size: rem-calc(25);
  }

  &__count-text {
    align-self: flex-start;
    font-weight: bold;
    padding: rem-calc(8) 0;
  }

  &__error-text {
    padding: rem-calc(16);
    text-align: center;
  }
}
</style>
