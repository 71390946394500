import axios from "axios";
import { msalInstance, loginRequest } from "@/authConfig";

const accessTokenRequest = {
  scopes: loginRequest.scopes,
};
export const axiosInstance = axios.create({
  timeout: 20000,
});
axiosInstance.interceptors.request.use(
  async (config) => {
    const { accessToken } = await msalInstance.acquireTokenSilent(
      accessTokenRequest
    );
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  },
  async (error) => {
    console.error(error);
    await msalInstance.acquireTokenRedirect(accessTokenRequest);
    Promise.reject(error);
  }
);

export default axiosInstance;
