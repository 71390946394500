import { reactive } from "vue";
import axios from "@/axios";
import { useAccount } from "@/composables/useAccount";
import config from "~/config";
import { useSearch } from "@/composables/useSearch";
import { ACCOUNT_TRAVELLER } from "@/constants";

const state = reactive({
  loading: false,
  traveller: {
    cardNumber: "",
    inAppPurchases: false,
    name: "",
    phoneNumber: "",
    subscription: false,
    travellerId: 0,
  },
});

export const useTraveller = () => {
  const account = useAccount();
  const search = useSearch();

  const setSelectedTraveller = (traveller) => {
    state.traveller = { ...state.traveller, ...traveller };
    delete state.traveller.tableInfo;
  };

  const addTraveller = async (traveller) => {
    const payload = {
      ...traveller,
      accountId: account.state.account.accountId,
    };
    try {
      const { data, status } = await axios.post(
        `${config.BASE_URL}/AddTraveller`,
        payload
      );
      account.addAccountTraveller(data);

      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const removeTraveller = async (travellerId, travellerType) => {
    const payload = { travellerId };
    try {
      const { status } = await axios.put(
        `${config.BASE_URL}/DeleteTraveller`,
        payload
      );
      if (travellerType === ACCOUNT_TRAVELLER) {
        account.removeAccountTraveller(travellerId);
      } else {
        search.updateTravellerSearchResult(travellerId);
      }

      return status === 204;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return {
    state,
    removeTraveller,
    addTraveller,
    setSelectedTraveller,
  };
};
