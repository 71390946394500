<template>
  <div class="st-bo-account-travellers">
    <st-spinner
      v-if="loading"
      aria-label="Laddar"
      class="st-bo-account-travellers__spinner"
    />
    <span v-else-if="error" class="st-bo-account-travellers__error-text">
      {{ error }}
    </span>
    <div v-else class="st-bo-account-travellers__content">
      <span>Antal inbjudna: {{ travellersInvited }}</span>
      <div class="st-bo-account-travellers__content__handle">
        <span>Antal resenärer: {{ travellers.length }}</span>
        <st-button
          primary
          @click="() => (showAddModal = true)"
          tabindex="0"
          :disabled="isNotAdmin"
        >
          Lägg till resenär
        </st-button>
      </div>
      <h4>Resenärer</h4>
      <st-table
        :list-data="travellers"
        :headers="headers"
        @on-item-click="rowClick"
      ></st-table>
      <add-traveller-modal
        v-if="showAddModal"
        :open="showAddModal"
        @close="() => (showAddModal = false)"
        @add="handleAdd"
      />
      <remove-modal
        :open="showRemoveModal.show"
        title="Ta bort resenär"
        :text="showRemoveModal.text"
        @remove="handleRemove"
        @close="() => (showRemoveModal.show = false)"
      />
      <confirmation-modal
        :open="showConfirmationModal.show"
        :title="showConfirmationModal.title"
        :status="showConfirmationModal.status"
        @close="() => (showConfirmationModal.show = false)"
      />
    </div>
  </div>
</template>
<script>
import { StTable, StButton, StSpinner } from "skanetrafiken/dist/legacy";
import { useAccount } from "@/composables/useAccount";
import { useUser } from "@/composables/useUser";
import { useTraveller } from "@/composables/useTraveller";
import { useTable } from "@/composables/useTable";
import { ref, computed, onMounted } from "vue";
import AddTravellerModal from "@/components/modals/AddTravellerModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import RemoveModal from "@/components/modals/RemoveModal.vue";
import { ACCOUNT_TRAVELLER } from "@/constants";

export default {
  name: "st-bo-account-travellers",
  components: {
    StButton,
    StTable,
    StSpinner,
    AddTravellerModal,
    ConfirmationModal,
    RemoveModal,
  },
  setup() {
    const account = useAccount();
    const user = useUser();
    const traveller = useTraveller();
    const table = useTable();

    const showAddModal = ref(false);
    const showRemoveModal = ref({ show: false, text: "" });
    const showConfirmationModal = ref({
      show: false,
      title: "",
      status: false,
    });
    let selectedTravellerId = 0;

    onMounted(async () => {
      await account.getAccountTravellers();
    });

    const handleAdd = async (newTraveller) => {
      let res = await traveller.addTraveller(newTraveller);
      showAddModal.value = false;
      showConfirmationModal.value = {
        show: true,
        title: res ? "Resenär tillagd." : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const handleRemove = async () => {
      const res = await traveller.removeTraveller(
        selectedTravellerId,
        ACCOUNT_TRAVELLER
      );
      showRemoveModal.value.show = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? "Resenär borttagen."
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const rowClick = (index, row) => {
      if (user.state.isAdmin) {
        selectedTravellerId =
          account.state.account.travellers[index].travellerId;
        showRemoveModal.value = {
          show: true,
          text: `Är du säker på att du vill ta bort ${row[2].value} från ${account.state.account.accountName}?`,
        };
      }
    };
    return {
      rowClick,
      headers: table.getTableHeaders(ACCOUNT_TRAVELLER),
      showAddModal,
      showConfirmationModal,
      showRemoveModal,
      handleAdd,
      handleRemove,
      loading: computed(() => account.state.accountTravellersLoading),
      travellers: computed(() =>
        account.state.account.travellers.map((traveller) => traveller.tableInfo)
      ),
      error: computed(() => account.state.errorTravellerTab),
      travellersInvited: computed(
        () => account.state.account.invitedTravellers
      ),
      isNotAdmin: computed(() => !user.state.isAdmin),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-account-travellers {
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    text-align: start;
    font-size: rem-calc(18);

    &__handle {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: rem-calc(22) 0;
      font-size: rem-calc(18);

      & > :first-child {
        margin-right: rem-calc(80);
      }
    }
  }

  &__spinner {
    margin-left: 20%;
    margin-top: rem-calc(16);
    align-self: flex-start;
  }

  &__error-text {
    margin-top: rem-calc(16);
    text-align: start;
    font-weight: bold;
  }
}
</style>
