<template>
  <st-modal class="st-bo-remove-modal" @esc-close="$emit('close')" v-if="open">
    <template v-slot:header>
      <h1>{{ title }}</h1>
    </template>
    <template v-slot:body>
      <p class="st-bo-remove-modal__paragraph">
        {{ text }}
      </p>
      <div class="st-bo-remove-modal__buttons">
        <st-button full-width @click="$emit('close')" tabindex="0">
          Avbryt
        </st-button>
        <st-button primary full-width @click="$emit('remove')" tabindex="0">
          Ta bort
        </st-button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import { StModal, StButton } from "skanetrafiken/dist/legacy";
export default {
  name: "st-bo-remove-modal",
  components: {
    StModal,
    StButton,
  },
  props: {
    open: { default: false },
    title: String,
    text: String,
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-remove-modal {
  text-align: center;
  &__paragraph {
    margin: rem-calc(30) 0 rem-calc(30) 0;
  }

  &__buttons {
    display: flex;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
