export default {
  phoneNumber: [
    {
      key: "required",
      message: "Telefonnummer är obligatoriskt.",
      id: "emailRequired",
    },
    {
      key: "phoneNumberFormat",
      message: "Felaktigt format på mobilnummer",
      id: "phoneNumberFormat",
    },
    {
      key: "maxLength",
      message: "Mobilnummer får inte vara mer än 30 tecken långt.",
      id: "mobileLength",
    },
  ],
  name: [
    {
      key: "required",
      message: "Namn är obligatoriskt.",
      id: "nameRequired",
    },
  ],
  cardNumber: [
    {
      key: "required",
      message: "Reskortsnummer är obligatoriskt.",
      id: "cardNumberRequired",
    },
    {
      key: "numericLength",
      message: "Felaktigt format på reskortsnummer.",
      id: "cardNumberNumeric",
    },
  ],
};
