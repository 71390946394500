<template>
  <st-modal
    class="st-bo-change-billing-address-modal"
    @esc-close="$emit('close')"
    v-if="open"
  >
    <template v-slot:header>
      <h1 class="st-bo-change-billing-address-modal__title">
        Byt fakturaadress
      </h1>
    </template>
    <template v-slot:body>
      <div class="st-bo-change-billing-address-modal__body">
        <span class="st-bo-change-billing-address-modal__body__span">
          Välj den fakturaadress du vill byta till
        </span>
        <div class="st-bo-change-billing-address-modal__body__select">
          <st-select
            :items="options"
            @change="(value) => handleSelect(value)"
            :value="selectedOption"
            :hasEmpty="false"
          />
        </div>
        <st-checkbox
          v-model="applyForAddressChecked"
          class="st-bo-change-billing-address-modal__body__checkbox"
          @click="handleChecked"
          tabindex="0"
          aria-label="Ansök om en annan fakturaadress"
          icon-description=""
        >
          Ansök om en annan fakturaadress
        </st-checkbox>
        <div
          v-if="applyForAddressChecked"
          class="st-bo-change-billing-address-modal__body__apply-inputs"
        >
          <st-form-group
            label="Adress"
            required
            :validation="v$.street"
            :validation-errors="validationErrors.street"
            :using-composition-api="true"
          >
            <st-input
              id="account-billing-information-input-address"
              tabindex="0"
              :required="true"
              @input="(value) => handleInput('street', value)"
              :invalid="v$.street.$error"
              :blur="() => v$.street.$touch()"
              :value="applyAddress.street"
            />
          </st-form-group>
          <st-form-group label="C/O">
            <st-input
              id="account-billing-information-input-co"
              tabindex="0"
              :invalid="false"
              :required="false"
              @input="(value) => handleInput('co', value)"
            />
          </st-form-group>
          <st-form-group
            label="Postnummer"
            required
            :validation="v$.postalCode"
            :validation-errors="validationErrors.postalCode"
            :using-composition-api="true"
          >
            <st-input
              id="account-billing-information-input-post"
              tabindex="0"
              :required="false"
              @input="(value) => handleInput('postalCode', value)"
              :invalid="v$.postalCode.$error"
              :blur="() => v$.postalCode.$touch()"
              :value="applyAddress.postalCode"
            />
          </st-form-group>
          <st-form-group
            label="Ort"
            required
            :validation="v$.city"
            :validation-errors="validationErrors.city"
            :using-composition-api="true"
          >
            <st-input
              id="account-billing-information-input-city"
              tabindex="0"
              :required="false"
              @input="(value) => handleInput('city', value)"
              :invalid="v$.city.$error"
              :blur="() => v$.city.$touch()"
              :value="applyAddress.city"
            />
          </st-form-group>
        </div>
        <div class="st-bo-change-billing-address-modal__body__buttons">
          <st-button
            primary
            fullWidth
            tabindex="0"
            @click="changeAddress"
            :disabled="!validAddress()"
          >
            {{
              applyForAddressChecked
                ? "Ansök om ny fakturaadress"
                : "Byt fakturaadress"
            }}
          </st-button>
          <st-button full-width @click="close()" tabindex="0">
            Avbryt
          </st-button>
        </div>
      </div>
    </template>
  </st-modal>
</template>

<script>
import {
  StModal,
  StButton,
  StInput,
  StCheckbox,
  StFormGroup,
} from "skanetrafiken/dist/legacy";
import { StSelect } from "skanetrafiken";
import { useAccount } from "@/composables/useAccount";
import { onUpdated, ref, computed, watch } from "vue";
import ChangeBillingAddressValidations from "@/validators/change-billing-address-validations";
import ChangeBillingAddressValidationsErrors from "@/validators/change-billing-address-validations-errors";
import { useVuelidate } from "@vuelidate/core";
export default {
  name: "st-bo-change-billing-address-modal",
  components: {
    StModal,
    StButton,
    StInput,
    StCheckbox,
    StSelect,
    StFormGroup,
  },
  props: {
    open: { default: false },
  },
  setup(_, { emit }) {
    const account = useAccount();
    const applyForAddressChecked = ref(false);
    const applyAddress = ref({
      id: "",
      street: "",
      co: "",
      postalCode: "",
      city: "",
      sveaAddressSelector: null,
      companyName: account.state.account.organizationName,
    });
    const v$ = useVuelidate(ChangeBillingAddressValidations, applyAddress);
    const addresses = ref([]);
    const selectedOption = ref(0);

    watch(account.state.account, () => {
      addresses.value = [];
      addresses.value = account.state.account.authorizedAddresses.map(
        (address, index) => {
          return {
            name: `${address.co || ""}${address.co ? "," : ""} ${
              address.street
            }, ${address.postalCode} ${address.city}`,
            id: index + 1,
          };
        }
      );
      addresses.value.unshift({ name: "", id: 0 });
      selectedOption.value = addresses.value[0].id;
    });

    onUpdated(() => {
      applyForAddressChecked.value = false;
      resetAddress();
      selectedOption.value = addresses.value[0].id;
    });

    const handleSelect = (e) => {
      const index = e.target.value;
      selectedOption.value = e.target.value;
      if (index == 0) {
        resetAddress();
      } else {
        Object.assign(
          applyAddress.value,
          account.state.account.authorizedAddresses[index - 1]
        );
      }
      applyForAddressChecked.value = false;
    };

    const handleChecked = () => {
      if (!applyForAddressChecked.value) {
        resetAddress();
        selectedOption.value = addresses.value[0].id;
      }
      applyForAddressChecked.value = !applyForAddressChecked.value;
    };

    const handleInput = (key, e) => {
      applyAddress.value[key] = e.target.value;
    };

    const validAddress = () => {
      return (
        applyAddress.value.street.length > 0 &&
        applyAddress.value.postalCode.length > 0 &&
        applyAddress.value.city.length > 0
      );
    };

    const resetAddress = () => {
      applyAddress.value = {
        id: "",
        street: "",
        co: "",
        postalCode: "",
        city: "",
        sveaAddressSelector: null,
        companyName: account.state.account.organizationName,
      };
    };

    const handleBlur = (prop) => {
      v$.value[prop].$touch();
    };

    const changeAddress = () => {
      v$.value.$touch();
      const errors = v$.value.$errors;
      if (errors.length) return;
      emit("updateAddress", applyAddress.value, applyForAddressChecked.value);
    };

    const close = () => {
      v$.value.$reset();
      emit("close");
    };

    return {
      account: computed(() => account.state.account),
      applyForAddressChecked,
      handleInput,
      options: computed(() => addresses.value),
      applyAddress,
      handleSelect,
      validAddress,
      handleChecked,
      selectedOption,
      v$,
      handleBlur,
      validationErrors: ChangeBillingAddressValidationsErrors,
      changeAddress,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-change-billing-address-modal {
  text-align: left;

  &__title {
    text-align: center;
    margin-bottom: rem-calc(16);
  }

  &__body {
    &__span {
      color: $dark-grey;
    }

    &__select {
      margin-top: rem-calc(10);
    }

    & > :not(:last-child) {
      margin-bottom: rem-calc(16);
    }

    &__checkbox {
      margin-top: 0;
    }

    &__buttons {
      display: flex;
      flex-direction: column;

      & > :last-child {
        margin-top: rem-calc(10);
      }
    }
  }
}
</style>
