<template>
  <st-modal
    class="st-bo-handle-purchase-modal"
    @esc-close="$emit('close')"
    v-if="open"
  >
    <template v-slot:header>
      <h1 class="st-bo-handle-purchase-modal__title">
        {{ actionTitle }} för köp
      </h1>
    </template>
    <template v-slot:body>
      <p class="st-bo-handle-purchase-modal__paragraph">
        Är du säker på att du vill {{ actionTitle.toLowerCase() }} detta konto
        för köp?
      </p>
      <div class="st-bo-handle-purchase-modal__buttons">
        <st-button full-width @click="$emit('close')" tabindex="0">
          Avbryt
        </st-button>
        <st-button primary full-width @click="$emit('change')" tabindex="0">
          {{ actionTitle }}
        </st-button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import { StModal, StButton } from "skanetrafiken/dist/legacy";
import { computed } from "vue";
export default {
  name: "st-bo-confirm-block-account-modal",
  components: {
    StModal,
    StButton,
  },
  props: {
    open: { default: false },
    purchaseAllowed: Boolean,
  },
  setup(props) {
    return {
      actionTitle: computed(() =>
        props.purchaseAllowed ? "Inaktivera" : "Aktivera"
      ),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-handle-purchase-modal {
  &__title {
    text-align: center;
  }

  &__paragraph {
    margin: rem-calc(30) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
