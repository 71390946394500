import { required, maxLength } from "@vuelidate/validators";
function phoneNumberFormat(value) {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  }

  let regex =
    /^(((0|((\+|00)46)|\+46)(7(0|2|3|6|9))(\d{7}))|((\+|00)(?!(46)))(\d{8,12}\b))/;
  return regex.test(value);
}

function numericLength(value) {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  }
  // valid card numbers are 1234 1234 1234 and 123412341234
  let regex = /^((\d{12})|((\d{4} ){2}\d{4}))$/;
  return regex.test(value);
}

export default {
  phoneNumber: {
    phoneNumberFormat,
    maxLength: maxLength(30),
    required,
  },
  name: {
    required,
  },
  cardNumber: {
    required,
    numericLength,
  },
};
