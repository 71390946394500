<template>
  <main class="st-org-bo">
    <div v-if="isAuthorized">
      <app-navigation></app-navigation>
      <router-view />
    </div>
    <div v-else-if="loading" class="st-org-bo__info">
      <st-spinner aria-label="Laddar" />
      <h3>Hämtar roller....</h3>
    </div>
    <div v-else class="st-org-bo__info">
      <h3>
        Du har inte behörighet till denna applikationen, kontakta Skånetrafikens
        administratörer.
      </h3>
    </div>
  </main>
</template>
<script>
import AppNavigation from "@/components/AppNavigation";
import { onMounted, ref, computed } from "vue";
import { msalInstance, loginRequest } from "@/authConfig";
import { useUser } from "@/composables/useUser";
import { StSpinner } from "skanetrafiken/dist/legacy";

const accessTokenRequest = {
  scopes: loginRequest.scopes,
};

export default {
  components: {
    AppNavigation,
    StSpinner,
  },
  setup() {
    const user = useUser();
    const isAuthorized = ref(false);

    onMounted(async () => {
      try {
        user.setLoading(true);
        const { accessToken } = await msalInstance.acquireTokenSilent(
          accessTokenRequest
        );
        isAuthorized.value = user.getRole(accessToken);
        user.setLoading(false);
      } catch (err) {
        if (
          err.errorCode === "interaction_required" ||
          err.errorCode === "login_required" ||
          err.errorCode === "no_account_error"
        ) {
          user.setLoading(false);
          msalInstance.loginRedirect(loginRequest);
        }
      }
    });

    return { isAuthorized, loading: computed(() => user.state.loading) };
  },
};
</script>

<style lang="scss">
@import "main.scss";
@import "~skanetrafiken/dist/scss/main.scss";
.st-org-bo {
  background: $white;
  text-align: center;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    & > :not(:last-child) {
      margin-bottom: rem-calc(16);
    }
  }
}
</style>
