import { required } from "@vuelidate/validators";

export default {
  street: {
    required,
  },
  postalCode: {
    required,
  },
  city: {
    required,
  },
};
