<template>
  <st-modal
    class="st-bo-add-traveller-modal"
    @esc-close="$emit('close')"
    v-if="open"
  >
    <template v-slot:header>
      <h1 class="st-bo-add-traveller-modal__title">Lägg till resenär</h1>
    </template>
    <template v-slot:body>
      <div class="st-bo-add-traveller-modal__inputs">
        <st-form-group
          label="Namn"
          required
          :validation="v$.name"
          :validation-errors="validationErrors.name"
          :using-composition-api="true"
        >
          <st-input
            id="add-traveller-modal-input-name"
            type="text"
            placeholder="Namn"
            @input="(value) => handleInput('name', value)"
            tabindex="0"
            :invalid="v$ && v$.name && v$.name.$error"
            :required="true"
            :value="traveller.name"
            :blur="() => handleBlur('name')"
          />
        </st-form-group>
        <st-form-group
          label="Telefonnummer"
          required
          :validation="v$.phoneNumber"
          :validation-errors="validationErrors.phoneNumber"
          :using-composition-api="true"
        >
          <st-input
            id="add-traveller-modal-input-phone"
            type="text"
            placeholder="Telefonnummer"
            @input="(value) => handleInput('phoneNumber', value)"
            tabindex="0"
            :invalid="v$ && v$.phoneNumber && v$.phoneNumber.$error"
            :required="true"
            :value="traveller.phoneNumber"
            :blur="() => handleBlur('phoneNumber')"
          />
        </st-form-group>
        <st-form-group
          label="Kortnummer"
          required
          :validation="v$.cardNumber"
          :validation-errors="validationErrors.cardNumber"
          :using-composition-api="true"
        >
          <st-input
            id="add-traveller-modal-input-card"
            type="text"
            placeholder="Kortnummer"
            @input="(value) => handleInput('cardNumber', value)"
            tabindex="0"
            :required="false"
            :blur="() => handleBlur('cardNumber')"
            :invalid="v$ && v$.cardNumber && v$.cardNumber.$error"
            :value="traveller.cardNumber"
          />
        </st-form-group>
      </div>
      <div class="st-bo-add-traveller-modal__buttons">
        <st-button full-width @click="$emit('close')" tabindex="0"
          >Avbryt</st-button
        >
        <st-button primary full-width @click="addTraveller" tabindex="0">
          Lägg till
        </st-button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import {
  StModal,
  StButton,
  StInput,
  StFormGroup,
} from "skanetrafiken/dist/legacy";
import { ref } from "vue";
import AddTravellerValidations from "@/validators/add-traveller-validations";
import AddTravellerValidationsErrors from "@/validators/add-traveller-validations-errors";
import { useVuelidate } from "@vuelidate/core";
export default {
  name: "st-bo-add-traveller-modal",
  components: {
    StModal,
    StButton,
    StInput,
    StFormGroup,
  },
  props: {
    open: { default: false },
  },
  setup(_, { emit }) {
    const traveller = ref({ name: "", cardNumber: "", phoneNumber: "" });
    const v$ = useVuelidate(AddTravellerValidations, traveller);

    const handleInput = (key, e) => {
      traveller.value[key] = e.target.value;
    };

    const handleBlur = (prop) => {
      v$.value[prop].$touch();
    };

    const addTraveller = () => {
      v$.value.$touch();
      const errors = v$.value.$errors;
      if (errors.length) return;
      emit("add", traveller.value);
    };

    return {
      traveller,
      handleInput,
      addTraveller,
      validationErrors: AddTravellerValidationsErrors,
      v$,
      handleBlur,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-add-traveller-modal {
  &__title {
    text-align: center;
  }

  &__inputs {
    margin: rem-calc(30) 0;

    & > :not(:last-child) {
      margin-bottom: rem-calc(30);
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
