import { createRouter, createWebHistory } from "vue-router";
import AccountView from "../views/AccountView.vue";
import AdminView from "../views/AdminView.vue";
import TravellerView from "../views/TravellerView.vue";
import AccountDetailsView from "../views/AccountDetailsView.vue";
import { registerGuard } from "./Guard";
const routes = [
  {
    path: "/",
    component: AccountView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account-details/:accountId",
    name: "AccountDetails",
    props: true,
    component: AccountDetailsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admins",
    name: "Admins",
    component: AdminView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/travellers",
    name: "Travellers",
    component: TravellerView,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active-link",
});

registerGuard(router);

export default router;
