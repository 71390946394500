import { ref } from "vue";
import { debounce } from "lodash";

export const useHandleWindowWidth = () => {
  const isSmallScreen = ref(false);

  const handleWindowWidth = () => {
    if (window.innerWidth >= 1150) {
      if (isSmallScreen.value) isSmallScreen.value = false;
    } else {
      if (!isSmallScreen.value) isSmallScreen.value = true;
    }
  };
  handleWindowWidth();
  window.addEventListener(
    "resize",
    debounce(handleWindowWidth, 50, { leading: false }),
    true
  );
  return { isSmallScreen };
};
