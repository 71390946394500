<template>
  <div class="st-bo-account-billing-information">
    <div class="st-bo-account-billing-information__header">
      <h4 class="st-bo-account-billing-information__header__title">
        Faktureringsuppgifter
      </h4>
      <status
        v-if="hasAddressPending()"
        title="Senaste adressändringen är just nu under granskning"
        pending
      />
    </div>
    <div class="st-bo-account-billing-information__addresses">
      <div>
        <address-information
          title="Fakturaadress"
          :address="account.invoiceInfo"
        />
        <st-button
          class="st-bo-account-billing-information__addresses__btn"
          :disabled="isNotAdmin || hasAddressPending()"
          tabindex="0"
          @click="() => (showAddressModal = true)"
        >
          Byt fakturaadress
        </st-button>
      </div>
      <address-information
        v-if="hasAddressPending()"
        title="Under granskning"
        :address="account.pendingInvoiceInfo"
      />
    </div>
    <div class="st-bo-account-billing-information__inputs">
      <st-input
        id="account-billing-information-input-ref"
        :label-name="'Fakturareferens'"
        :value="account.reference"
        tabindex="0"
        :invalid="false"
        :required="false"
        :disabled="!editBilling"
        @input="(value) => handleInput('reference', value)"
      />
      <label class="st-bo-account-billing-information__inputs__label"
        >Faktura vald som:</label
      >
      <st-select
        :items="options"
        @change="(value) => handleInput('distributionType', value)"
        :value="selectedOption"
        :hasEmpty="false"
        :disabled="!editBilling"
      />

      <div class="st-bo-account-billing-information__inputs__optional">
        <st-input
          v-if="mailSelected"
          id="account-billing-information-input-mail"
          :label-name="'Mejladress'"
          :value="account.invoiceEmail"
          tabindex="0"
          :invalid="false"
          :required="false"
          :disabled="!editBilling"
          @input="(value) => handleInput('invoiceEmail', value)"
        />
        <st-input
          v-else-if="peppolSelected"
          id="account-billing-information-input-peppol"
          :label-name="'Peppol ID'"
          :value="account.peppolId"
          tabindex="0"
          :invalid="false"
          :required="false"
          :disabled="!editBilling"
          @input="(value) => handleInput('peppolId', value)"
        />
      </div>
    </div>
    <div class="st-bo-account-billing-information__buttons">
      <st-button
        primary
        @click="handleUpdate"
        tabindex="0"
        :is-loading="billingLoading"
        :disabled="isNotAdmin"
      >
        {{ editBilling ? "Spara ändringar" : "Ändra uppgifter" }}
      </st-button>
    </div>
    <div class="st-bo-account-billing-information__inputs__svea">
      <div class="st-bo-account-billing-information__inputs__svea__input">
        <st-input
          id="account-billing-information-input-key"
          :label-name="'SVEA nyckel'"
          tabindex="0"
          :invalid="false"
          :required="false"
          :value="account.invoiceInfo.sveaPublicKey"
          disabled
        />
        <st-button
          class="st-bo-account-billing-information__inputs__svea__input__button"
          @click="() => (showSveaKeyModal = true)"
          :disabled="isNotAdmin || hasAddressPending()"
          tabindex="0"
        >
          Ändra SVEA nyckel
        </st-button>
      </div>
      <span class="st-bo-account-billing-information__inputs__svea__text">
        Viktigt! En ändring av en svea-nyckel påverkar samtliga konton under
        denna address.
      </span>
    </div>
  </div>
  <change-billing-address-modal
    :open="showAddressModal"
    @updateAddress="handleAddressUpdate"
    @close="() => (showAddressModal = false)"
  />
  <svea-key-modal
    :open="showSveaKeyModal"
    @update="handleSveaKeyUpdate"
    @close="() => (showSveaKeyModal = false)"
  />
  <confirmation-modal
    :open="showConfirmationModal.show"
    :title="showConfirmationModal.title"
    :status="showConfirmationModal.status"
    @close="() => (showConfirmationModal.show = false)"
  />
</template>

<script>
import { StInput, StButton } from "skanetrafiken/dist/legacy";
import { StSelect } from "skanetrafiken";
import ChangeBillingAddressModal from "@/components/modals/ChangeBillingAddressModal.vue";
import SveaKeyModal from "@/components/modals/SveaKeyModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Status from "@/components/common/Status.vue";
import AddressInformation from "@/components/common/AddressInformation.vue";
import { useAccount } from "@/composables/useAccount";
import { useUser } from "@/composables/useUser";
import { computed, ref } from "vue";

export default {
  name: "st-bo-account-billing-information",
  components: {
    StInput,
    StButton,
    StSelect,
    ChangeBillingAddressModal,
    SveaKeyModal,
    ConfirmationModal,
    Status,
    AddressInformation,
  },
  setup() {
    const account = useAccount();
    const user = useUser();
    const editBilling = ref(false);
    const billingLoading = ref(false);
    const showAddressModal = ref(false);
    const showSveaKeyModal = ref(false);
    const showConfirmationModal = ref({
      show: false,
      title: "",
      status: false,
    });
    const options = [
      { name: "Pappersfaktura", id: 1 },
      { name: "Mejladress", id: 2 },
      { name: "Peppol", id: 4 },
    ];

    const handleInput = (key, e) => {
      account.state.account[key] = e.target.value;
    };

    const handleSveaKeyUpdate = async (newSveaPublicKey) => {
      const res = await account.updateSveaKey(newSveaPublicKey);
      showSveaKeyModal.value = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? "SVEA Nyckeln är nu uppdaterad."
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const hasAddressPending = () => {
      return !!account.state.account.pendingInvoiceInfo;
    };

    const handleAddressUpdate = async (address, applied) => {
      const isNewAddress = applied ? true : false;
      const res = await account.updateInvoiceInfo(address, isNewAddress);
      showAddressModal.value = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? applied
            ? "Ansökan om adress ändring har nu skickats till SVEA"
            : "Adressen är nu uppdaterad"
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const handleUpdate = async () => {
      if (editBilling.value) {
        const isNewAddress = false;
        billingLoading.value = true;
        const res = await account.updateInvoiceInfo(
          account.state.account.invoiceInfo,
          isNewAddress
        );
        showConfirmationModal.value = {
          show: true,
          title: res
            ? "Dina faktureringsuppgifter har nu uppdaterats."
            : "Oops, verkar som något gick snett!",
          status: res,
        };
        billingLoading.value = false;
        editBilling.value = false;
      } else {
        editBilling.value = true;
      }
    };

    return {
      account: computed(() => account.state.account),
      handleAddressUpdate,
      handleInput,
      handleUpdate,
      options,
      selectedOption: computed(() => account.state.account.distributionType),
      showAddressModal,
      showSveaKeyModal,
      showConfirmationModal,
      handleSveaKeyUpdate,
      hasAddressPending,
      peppolSelected: computed(
        () => account.state.account.distributionType == 4
      ),
      mailSelected: computed(() => account.state.account.distributionType == 2),
      isNotAdmin: computed(() => !user.state.isAdmin),
      editBilling,
      billingLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-account-billing-information {
  border: rem-calc(1) solid $dark-grey;
  border-radius: rem-calc(6);
  padding: rem-calc(16);
  text-align: left;

  @include large {
    width: 70vw;
  }

  & > * {
    margin-bottom: rem-calc(16);
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include large {
      flex-direction: row;
    }

    & > :not(:last-child) {
      margin-right: rem-calc(32);
    }
  }

  &__addresses {
    display: flex;
    flex-direction: column;
    border-radius: rem-calc(6);
    background-color: #f5f3f0;
    padding: rem-calc(8);

    @include large {
      flex-direction: row;
    }

    & > :first-child {
      margin-bottom: rem-calc(16);
      @include large {
        margin-bottom: 0;
        margin-right: rem-calc(100);
      }
    }
  }

  &__inputs {
    & > * {
      margin-bottom: rem-calc(16);
    }

    &__label {
      display: flex;
      justify-content: flex-start;
      padding-left: rem-calc(4);
      margin-bottom: rem-calc(2);
      font-size: rem-calc(14);
      color: $dark-grey;
      font-weight: 600;
    }

    &__svea {
      display: flex;
      flex-direction: column;

      &__input {
        display: flex;
        flex-direction: column;

        @include large {
          flex-direction: row;

          &__button {
            align-self: end;
            margin-left: rem-calc(10);
          }
        }

        &__button {
          margin-top: rem-calc(15);
        }
      }

      &__text {
        margin-top: rem-calc(8);
      }
    }
  }

  &__buttons {
    display: flex;
    border-bottom: rem-calc(1) solid $dark-grey;
    padding-bottom: rem-calc(20);

    & > :not(:last-child) {
      margin-right: rem-calc(16);
    }
  }
}
</style>
