<template>
  <div class="st-bo-address-information">
    <h4 class="st-bo-address-information__title">{{ title }}</h4>
    <span class="st-bo-address-information__text">{{ address.co }}</span>
    <span class="st-bo-address-information__text">
      {{ address.street }}
    </span>
    <span class="st-bo-address-information__text">
      {{ address.postalCode }} {{ address.city }}
    </span>
  </div>
</template>
<script>
export default {
  name: "st-bo-address-information",
  props: {
    //Should contain street, C/O, postalCode, city
    title: String,
    address: Object,
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-address-information {
  margin-bottom: rem-calc(15);

  & > * {
    margin-bottom: rem-calc(8);
  }

  &__text {
    display: block;
  }
}
</style>
