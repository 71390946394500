<template>
  <div class="st-bo-traveller-view">
    <h1 class="st-bo-traveller-view__title">Resenär</h1>
    <search :search-type="TRAVELLER" />
    <h2 class="st-bo-traveller-view__subtitle">Resenärer</h2>
    <span v-if="result.length" class="st-bo-traveller-view__count-text">
      Antal resultat: {{ result.length }}
    </span>
    <st-table
      :list-data="result"
      :headers="headers"
      @onItemClick="deleteClick"
    />
    <st-spinner v-if="loading" aria-label="Laddar" />
    <span v-if="error" class="st-bo-traveller-view__error-text">
      {{ error }}
    </span>
    <remove-modal
      :open="showRemoveModal.show"
      title="Ta bort resenär"
      :text="showRemoveModal.text"
      @remove="handleRemove"
      @close="() => (showRemoveModal.show = false)"
    />
    <confirmation-modal
      :open="showConfirmationModal.show"
      :title="showConfirmationModal.title"
      :status="showConfirmationModal.status"
      @close="() => (showConfirmationModal.show = false)"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "vue";
import Search from "@/components/Search.vue";
import { useSearch } from "@/composables/useSearch";
import { useTable } from "@/composables/useTable";
import { useUser } from "@/composables/useUser";
import { useTraveller } from "@/composables/useTraveller";
import { StTable, StSpinner } from "skanetrafiken/dist/legacy";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import RemoveModal from "@/components/modals/RemoveModal.vue";
import { TRAVELLER } from "@/constants";

export default {
  name: "st-bo-traveller-view",
  components: {
    Search,
    StTable,
    StSpinner,
    ConfirmationModal,
    RemoveModal,
  },
  setup() {
    const search = useSearch();
    const table = useTable();
    const user = useUser();
    const traveller = useTraveller();

    const showRemoveModal = ref({ show: false, text: "" });
    const showConfirmationModal = ref({
      show: false,
      title: "",
      status: false,
    });

    onBeforeMount(() => {
      search.clearSearch();
    });

    const handleRemove = async () => {
      const res = await traveller.removeTraveller(
        traveller.state.traveller.travellerId,
        TRAVELLER
      );
      showRemoveModal.value.show = false;
      showConfirmationModal.value = {
        show: true,
        title: res
          ? `${traveller.state.traveller.name} är nu borttagen.`
          : "Oops, verkar som något gick snett!",
        status: res,
      };
    };

    const deleteClick = (index) => {
      if (user.state.isAdmin) {
        traveller.setSelectedTraveller(search.state.result[index]);
        showRemoveModal.value = {
          show: true,
          text: `Är du säker på att du vill ta bort ${traveller.state.traveller.name}?`,
        };
      }
    };

    return {
      headers: table.getTableHeaders(TRAVELLER),
      result: computed(() =>
        search.state.result.map((account) => account.tableInfo)
      ),
      error: computed(() => search.state.resultError),
      loading: computed(() => search.state.loading),
      deleteClick,
      TRAVELLER,
      showConfirmationModal,
      showRemoveModal,
      handleRemove,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-traveller-view {
  padding: rem-calc(20);
  display: flex;
  flex-direction: column;
  text-align: start;

  &__title {
    text-align: start;
  }

  &__subtitle {
    text-align: start;
    font-size: rem-calc(25);
  }

  &__count-text {
    align-self: flex-start;
    font-weight: bold;
    padding: rem-calc(8) 0;
  }
  &__error-text {
    padding: rem-calc(16);
    text-align: center;
  }
}
</style>
