<template>
  <div class="st-bo-account-details-view">
    <h1 class="st-bo-account-details-view__title">Konto</h1>
    <account-details />
    <account-tab-bar />
  </div>
</template>

<script>
import AccountDetails from "@/components/AccountDetails.vue";
import AccountTabBar from "@/components/AccountTabBar.vue";
import { useAccount } from "@/composables/useAccount";
import { onBeforeMount, onMounted } from "vue";

export default {
  name: "st-bo-account-details-view",
  components: {
    AccountDetails,
    AccountTabBar,
  },
  props: {
    accountId: String,
  },
  setup(props) {
    const account = useAccount();

    onBeforeMount(() => {
      account.setSelectedAccount(props.accountId);
    });

    onMounted(async () => {
      await account.getAccountDetails(props.accountId);
    });
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-account-details-view {
  padding: rem-calc(20);

  &__title {
    text-align: start;
  }

  & > :not(:last-child) {
    margin-bottom: rem-calc(32);
  }
}
</style>
