<template>
  <st-modal
    class="st-bo-block-account-modal"
    @esc-close="$emit('close', false)"
    v-if="open"
  >
    <template v-slot:header>
      <h1 class="st-bo-block-account-modal__title">{{ actionTitle }} konto</h1>
    </template>
    <template v-slot:body>
      <p class="st-bo-block-account-modal__paragraph">
        {{
          blockOnOrgNumber
            ? `Är du säker på att du vill ${actionTitle.toLowerCase()} alla konton under organisationsnummer ${organizationNumber}?`
            : `Är du säker på att du vill ${actionTitle.toLowerCase()} ${accountName}?`
        }}
      </p>
      <div class="st-bo-block-account-modal__buttons">
        <st-button full-width @click="$emit('close', false)" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          full-width
          @click="$emit('block', blockOnOrgNumber)"
          tabindex="0"
        >
          {{ actionTitle }} konto
        </st-button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import { StModal, StButton } from "skanetrafiken/dist/legacy";
import { computed } from "vue";
export default {
  name: "st-bo-confirm-block-account-modal",
  components: {
    StModal,
    StButton,
  },
  props: {
    open: { default: false },
    accountName: String,
    blockOnOrgNumber: Boolean,
    organizationNumber: String,
    isBlocked: Boolean,
  },
  setup(props) {
    return {
      actionTitle: computed(() => (props.isBlocked ? "Aktivera" : "Spärra")),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-block-account-modal {
  &__title {
    text-align: center;
  }

  &__paragraph {
    margin: rem-calc(40) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > * {
      line-height: rem-calc(22);
    }

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
