import { reactive } from "vue";
import axios from "@/axios";
import { useTable } from "@/composables/useTable";
import { ACCOUNT_TRAVELLER, ADMIN } from "@/constants";
import config from "~/config";

const state = reactive({
  loading: false,
  accountAdminsLoading: false,
  errorAdminTab: "",
  accountTravellersLoading: false,
  errorTravellerTab: "",
  account: {
    accountId: 0,
    organizationNumber: "",
    organizationName: "",
    accountName: "",
    ugNumber: "",
    peppolId: "",
    isCreditWorthy: false,
    blocked: false,
    reference: "",
    invoiceEmail: "",
    distributionType: 1,
    authorizedAddresses: [],
    invoiceInfo: {
      street: "",
      co: "",
      postalCode: "",
      city: "",
      sveaPublicKey: "",
      sveaAddressSelector: "",
    },
    pendingInvoiceInfo: undefined,
    travellers: [],
    invitedTravellers: 0,
    admins: [],
  },
});

export const useAccount = () => {
  const table = useTable();

  const getAccountDetails = async (accountId) => {
    try {
      state.loading = true;
      const { data } = await axios.get(
        `${config.BASE_URL}/GetDetailedAccountInfo?accountId=${accountId}`
      );
      Object.assign(state.account, data.accountsDetails);
      state.loading = false;
    } catch (err) {
      console.log(err);
      state.loading = false;
    }
  };

  const getAccountAdmins = async () => {
    try {
      state.accountAdminsLoading = true;
      state.errorAdminTab = "";
      const { data } = await axios.get(
        `${config.BASE_URL}/GetUsersByAccount?accountId=${state.account.accountId}`
      );
      state.account.admins = data.admins.map((admin) => {
        return {
          tableInfo: table.getTableInfo(ADMIN, admin),
          ...admin,
        };
      });
      state.accountAdminsLoading = false;
    } catch (error) {
      state.errorAdminTab = `Det verkar inte gå att hämta administratörer för detta konto just nu. Vänligen testa senare igen! ${error}`;
      console.log(error);
      state.accountAdminsLoading = false;
    }
  };

  const getAccountTravellers = async () => {
    try {
      state.accountTravellersLoading = true;
      state.errorTravellerTab = "";
      const { data } = await axios.get(
        `${config.BASE_URL}/GetTravellersByAccount?accountId=${state.account.accountId}`
      );

      state.account.travellers = data.travellers.map((traveller) => {
        return {
          tableInfo: table.getTableInfo(ACCOUNT_TRAVELLER, traveller),
          ...traveller,
        };
      });
      state.account.invitedTravellers = data.invitedToBuyInApp;
      state.accountTravellersLoading = false;
    } catch (error) {
      state.errorTravellerTab = `Det verkar inte gå att hämta resenärer för detta konto just nu. Vänligen testa senare igen! ${error}`;
      console.log(error);
      state.accountTravellersLoading = false;
    }
  };

  const setSelectedAccount = (accountId) => {
    state.account = { ...state.account, accountId };
  };

  const updateAccountBlock = async (blockOnOrgNumber) => {
    const blocked = !state.account.blocked;
    const payload = {
      accountId: state.account.accountId,
      organizationNumber: state.account.organizationNumber,
      blocked,
      allAccounts: blockOnOrgNumber,
    };
    try {
      const { data, status } = await axios.put(
        `${config.BASE_URL}/UpdateBlocked`,
        payload
      );
      console.log(data);
      state.account = { ...state.account, blocked };
      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getAccountOrgName = async () => {
    try {
      const { data, status } = await axios.get(
        `${config.BASE_URL}/UpdateCompanyName?organizationNumber=${state.account.organizationNumber}`
      );
      state.account = {
        ...state.account,
        organizationName: data,
      };
      return status === 200;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updatePurchaseStatus = async (isCreditWorthy) => {
    const payload = { accountId: state.account.accountId, isCreditWorthy };
    try {
      const { data, status } = await axios.put(
        `${config.BASE_URL}/UpdateIsCreditWorthy`,
        payload
      );
      state.account = { ...state.account, isCreditWorthy: data.isCreditWorthy };
      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const updateInvoiceInfo = async (address, isNewAddress) => {
    const payload = {
      account_Id: parseInt(state.account.accountId),
      invoiceEmail: state.account.invoiceEmail,
      distributionType: state.account.distributionType,
      reference: state.account.reference,
      peppolId: state.account.peppolId,
      address: {
        ...address,
      },
    };
    try {
      const { data, status } = await axios.put(
        `${config.BASE_URL}/UpdateInvoiceInfo`,
        payload
      );
      state.account = {
        ...state.account,
        invoiceInfo: isNewAddress ? data.account.invoiceInformation : address,
        pendingInvoiceInfo: isNewAddress
          ? data.pendingInvoiceInformation
          : undefined,
      };
      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const updateAccountDetails = async () => {
    const payload = {
      accountId: state.account.accountId,
      displayName: state.account.accountName,
      sveaAccountTypeNumber: state.account.ugNumber,
    };
    try {
      const { data, status } = await axios.put(
        `${config.BASE_URL}/UpdateAccountInfo`,
        payload
      );
      state.account = { ...state.account, data };
      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const updateSveaKey = async (newSveaPublicKey) => {
    const payload = {
      accountId: state.account.accountId,
      newKey: newSveaPublicKey,
    };
    try {
      const { status } = await axios.put(
        `${config.BASE_URL}/UpdateSveaPublicKey`,
        payload
      );
      state.account.invoiceInfo.sveaPublicKey = newSveaPublicKey;
      return status === 200;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const isSelectedAccount = (accountId) => {
    return parseInt(state.account.accountId) === accountId;
  };

  const removeAccountTraveller = (travellerId) => {
    state.account.travellers = state.account.travellers.filter(
      (traveller) => traveller.travellerId !== travellerId
    );
  };

  const addAccountTraveller = (accountTraveller) => {
    state.account.travellers.push({
      tableInfo: table.getTableInfo(ACCOUNT_TRAVELLER, accountTraveller),
      ...accountTraveller,
    });
  };

  const updateAccountAdmin = (admin) => {
    state.account.admins = state.account.admins.map((existingAdmin) =>
      existingAdmin.userId === admin.userId ? admin : existingAdmin
    );
  };

  const removeAccountAdmin = (userId) => {
    state.account.admins = state.account.admins.filter(
      (admin) => admin.userId !== userId
    );
  };

  return {
    state,
    getAccountDetails,
    setSelectedAccount,
    updateAccountBlock,
    updatePurchaseStatus,
    getAccountAdmins,
    getAccountTravellers,
    updateInvoiceInfo,
    updateAccountDetails,
    addAccountTraveller,
    removeAccountTraveller,
    getAccountOrgName,
    updateSveaKey,
    updateAccountAdmin,
    removeAccountAdmin,
    isSelectedAccount,
  };
};
