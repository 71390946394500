<template>
  <st-modal
    class="st-bo-confirmation-modal"
    @esc-close="$emit('close')"
    v-if="open"
  >
    <template v-slot:body>
      <checkmark class="st-bo-confirmation-modal__icon" v-if="status" />
      <span class="st-bo-confirmation-modal__text">
        {{ title }}
      </span>
      <st-button full-width @click="$emit('close')" tabindex="0">
        Stäng
      </st-button>
    </template>
  </st-modal>
</template>

<script>
import { StModal, StButton } from "skanetrafiken/dist/legacy";
import Checkmark from "@/components/common/Checkmark.vue";
export default {
  name: "st-bo-confirmation-modal",
  components: {
    StModal,
    StButton,
    Checkmark,
  },
  props: {
    open: { default: false },
    status: Boolean,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-confirmation-modal {
  &__icon {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__text {
    display: block;
    text-align: center;
    font-size: rem-calc(30);
    font-weight: bold;
    padding: rem-calc(32) 0;
    color: $grey-dark;
  }
}
</style>
