<template>
  <div class="st-bo-account-tab-bar">
    <tabs>
      <tab title="Faktureringsuppgifter">
        <component :is="AccountDetails"></component>
      </tab>
      <tab title="Administratörer">
        <component :is="AccountAdmins"></component>
      </tab>
      <tab title="Resenärer">
        <component :is="AccountTravellers"></component>
      </tab>
    </tabs>
  </div>
</template>
<script>
import Tabs from "@/components/common/Tabs.vue";
import Tab from "@/components/common/Tab.vue";
import AccountBillingInformation from "@/components/AccountBillingInformation.vue";
import AccountAdmins from "@/components/AccountAdmins.vue";
import AccountTravellers from "@/components/AccountTravellers.vue";

export default {
  name: "st-bo-account-tab-bar",
  components: { Tabs, Tab },
  setup() {
    return {
      AccountDetails: AccountBillingInformation,
      AccountAdmins: AccountAdmins,
      AccountTravellers: AccountTravellers,
    };
  },
};
</script>
