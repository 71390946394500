import { required, maxLength, email, numeric } from "@vuelidate/validators";

export default {
  phoneNumber: {
    numeric,
    maxLength: maxLength(30),
    required,
  },
  email: {
    required,
    emailFormat: email,
    maxLength: maxLength(100),
  },
};
