<template>
  <st-modal
    class="st-bo-svea-key-modal"
    @esc-close="$emit('close')"
    v-if="open"
  >
    <template v-slot:header>
      <h1 class="st-bo-svea-key-modal__title">SVEA Nyckel</h1>
    </template>
    <template v-slot:body>
      <div class="st-bo-svea-key-modal__input">
        <st-form-group label="SVEA Nyckel" :using-composition-api="true">
          <st-input
            id="svea-key-modal-input-key"
            type="text"
            :value="account.invoiceInfo.sveaPublicKey"
            @input="(value) => handleInput('sveaPublicKey', value)"
            tabindex="0"
            :required="true"
            :invalid="false"
          />
        </st-form-group>
      </div>
      <div class="st-bo-svea-key-modal__buttons">
        <st-button full-width @click="$emit('close')" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          full-width
          @click="$emit('update', newSveaPublicKey)"
          tabindex="0"
        >
          Uppdatera
        </st-button>
      </div>
    </template>
  </st-modal>
</template>

<script>
import {
  StModal,
  StButton,
  StInput,
  StFormGroup,
} from "skanetrafiken/dist/legacy";
import { computed, ref } from "vue";
import { useAccount } from "@/composables/useAccount";
export default {
  name: "st-bo-svea-key-modal",
  components: {
    StModal,
    StButton,
    StInput,
    StFormGroup,
  },
  props: {
    open: { default: false },
  },
  setup() {
    const account = useAccount();
    const newSveaPublicKey = ref("");

    const handleInput = (key, e) => {
      newSveaPublicKey.value = e.target.value;
    };

    return {
      account: computed(() => account.state.account),
      handleInput,
      newSveaPublicKey,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-svea-key-modal {
  &__title {
    text-align: center;
  }

  &__input {
    text-align: left;
    margin: rem-calc(30) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
