<template>
  <div v-show="isActive" class="tab">
    <slot></slot>
  </div>
</template>

<script>
import { ref, inject, watch, onBeforeMount } from "vue";

export default {
  name: "Tab",
  props: {
    title: { type: String, default: "Tab" },
  },

  setup() {
    const index = ref(0);
    const isActive = ref(true);

    const tabs = inject("TabsProvider");

    watch(
      () => tabs.selectedIndex,
      () => {
        isActive.value = index.value === tabs.selectedIndex;
      }
    );

    onBeforeMount(() => {
      index.value = tabs.count;
      tabs.count++;
      isActive.value = index.value === tabs.selectedIndex;
    });

    return { isActive, index };
  },
};
</script>
<style lang="scss"></style>
