<template>
  <div class="st-bo-account-view">
    <h1 class="st-bo-account-view__title">Konto</h1>
    <search :search-type="ACCOUNT" />
    <h2 class="st-bo-account-view__subtitle">Kontolista</h2>
    <span v-if="result.length" class="st-bo-account-view__count-text">
      Antal resultat: {{ result.length }}
    </span>
    <st-table :list-data="result" :headers="headers" @on-click="rowClick" />
    <st-spinner v-if="loading" aria-label="Laddar" />
    <span v-if="error" class="st-bo-account-view__error-text">{{ error }}</span>
  </div>
</template>

<script>
import { computed, onBeforeMount } from "vue";
import Search from "@/components/Search.vue";
import { useSearch } from "@/composables/useSearch";
import { useAccount } from "@/composables/useAccount";
import { useTable } from "@/composables/useTable";
import { StTable, StSpinner } from "skanetrafiken/dist/legacy";
import { ACCOUNT } from "@/constants";
import router from "@/router";

export default {
  name: "st-bo-account-view",
  components: {
    Search,
    StTable,
    StSpinner,
  },
  setup() {
    const search = useSearch();
    const account = useAccount();
    const table = useTable();

    onBeforeMount(() => {
      search.clearSearch();
    });

    const rowClick = (row, index) => {
      account.setSelectedAccount(search.state.result[index].accountId);
      router.push(`/account-details/${account.state.account.accountId}`);
    };

    return {
      headers: table.getTableHeaders(ACCOUNT),
      result: computed(() =>
        search.state.result.map((account) => account.tableInfo)
      ),
      error: computed(() => search.state.resultError),
      loading: computed(() => search.state.loading),
      rowClick,
      ACCOUNT,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-account-view {
  padding: rem-calc(20);
  display: flex;
  flex-direction: column;
  text-align: start;

  &__title {
    text-align: start;
  }

  &__subtitle {
    text-align: start;
    font-size: rem-calc(25);
  }

  &__count-text {
    align-self: flex-start;
    font-weight: bold;
    padding: rem-calc(8) 0;
  }
  &__error-text {
    padding: rem-calc(16);
    text-align: center;
  }
}
</style>
